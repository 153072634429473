<template>
  <component
    :is="to ? 'a' : 'button'"
    :class="classes"
    :href="href"
    :style="style"
    type="button"
    @click="onClick"
  >
    <IconSvg
      v-if="icon"
      :name="icon"
    />
    {{ label }}
    <slot />
    <IconSvg
      v-if="hasChevron"
      name="chevron"
    />
  </component>
</template>
<script lang="ts">
import Vue from 'vue'
import IconSvg from '@/components/atoms/IconSvg.vue'

export default Vue.extend({
  name: 'Button',
  components: {
    IconSvg
  },
  props: {
    label: {
      type: String,
      default: null
    },
    size: {
      type: String,
      default: 'medium',
      validator: value => ['small', 'medium', 'large'].includes(value)
    },
    icon: {
      type: String,
      default: ''
    },
    hasChevron: {
      type: Boolean,
      default: false
    },
    warning: {
      type: Boolean,
      default: false
    },
    backgroundColor: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
    to: {
      type: [String, Object, null],
      default: null
    },
    target: {
      type: String,
      default: '_self'
    }
  },
  computed: {
    classes () {
      return {
        button: true,
        'has-chevron': this.hasChevron,
        disabled: this.disabled === true,
        small: this.size === 'small',
        medium: this.size === 'medium',
        large: this.size === 'large',
        warning: this.warning
      }
    },
    style () {
      if (this.backgroundColor) {
        return `background:${this.backgroundColor}`
      }
      return ''
    },
    href(){
      if(this.to){
        return this.$router.resolve(this.to).href
      }
      return null
    }
  },
  methods: {
    onClick (e) {
      this.$emit('click')
      if (this.to){
        e.preventDefault()
        if(this.target === '_self') {
          this.$router.push(this.to)
        }else{
          window.open(this.href, this.target)
        }
      }
    }
  }
})

</script>
<style scoped lang="less">

  @import '../../less/variables';

  button, a {
    background: @grayDark;
    font: inherit;
    padding: 0.75em 1.75em;
    border-radius: .5em;
    cursor: pointer;
    font-weight: 500;
    outline: none;
    margin-top: 1em;
    margin-bottom: 1em;
    position: relative;
    border: 0;
    box-shadow: 0px 3px 5px rgba(0,0,0,0.1);
    color: #fff;
    white-space: nowrap;
    transition: background 0.2s ease-in-out;
    display: inline-block;

    &.link{
      color: @grayDark;
      background: transparent;
      box-shadow: none;
      padding: .4em 0.75em;
      &:hover, &:active {
        color: @black;
      }
    }
    &.warning {
      color: black;
      background: transparent;
      &:hover{
        color: @red;
      }
    }
    &.full-width{
      width: 100%;
    }
    &.primary {
      background: @purpleVibrant;
      &:hover, &:active {
        background: lighten(@purpleVibrant, 5%)
      }
      .icon {
        height: 1.5em;
        width: 1.5em;
      }
    }
    &.special {
      background: -webkit-linear-gradient(45deg, #015ce5, #a340f5, #fc6169);
      &:hover, &:active {
        background: -webkit-linear-gradient(45deg, lighten(#015ce5, 5%), lighten(#a340f5, 5%), lighten(#fc6169, 5%));
      }
      .icon {
        height: 1.5em;
        width: 1.5em;
      }
    }
    &.uppercase{
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: 600;
    }
    &.capitalize{
      text-transform: capitalize;
    }

    &.secondary {
      background: @black;
      color: @grayLight;
      box-shadow: 0px 3px 5px rgba(0,0,0,0.2);
      &:hover, &:active {
        opacity: 0.8;
      }
      .icon {
        height: 1.5em;
        width: 1.5em;
      }
    }
    &.warning-final{
        background: @red !important;
        color: white;
    }

    &.white {
      background: @white;
      color: @grayDark;
      box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
      transition: box-shadow 100ms;
      &:hover{
        box-shadow: 0px 2px 6px rgba(0,0,0,0.1);
        background: @white;
        color: @black;
      }
      &:active {
        background: @grayLight;
      }
      .icon {
        height: 1.5em;
        width: 1.5em;
      }
    }
    &.disabled {
      background: @gray2;
      color: @grayLightMedium;
      box-shadow: none;
      cursor: not-allowed;
      &:hover{
        background: @gray2;
        color: @grayLightMedium;
      }
    }
    &.outline{
      background: transparent;
      border: 2px solid @grayMedium;
      color: @black;
    }
    &.rounded{
      border-radius: 2em;
      padding-left: 1.5em;
      padding-right: 1.5em;
    }

    &.block{
      width: 100%;
    }
    &.wrap{
      white-space: normal;
    }

    &.has-chevron .chevron{
      margin-right: -.5em;
      font-size: 0.9em;
    }
    &.small {
      font-size: 80%;
    }
    &.medium {

    }
    &.large {
      font-size: 120%;
    }
    &.circle{
      height: 2.5em;
      width: 2.5em;
      padding: 0;
      border-radius: 50%;
      margin: 0;
      .icon {
        margin-right: revert;
      }
    }
    &.transparent {
      background: transparent;
      box-shadow: none;
      color: @grayMedium;
      &:hover, &:active{
        background: @grayLight;
        color: @grayDark;
      }
    }
    &.rotate-icon-90 .icon{
      transform: rotate(90deg);
    }
    &.rotate-icon-180 .icon{
      transform: rotate(180deg);
    }
    &.rotate-icon-270 .icon{
      transform: rotate(270deg);
    }
  }

  .icon {
    margin-right: 0.25em;
  }

  .shimmer{
    background: -webkit-linear-gradient(45deg, #015ce5, #a340f5, #fc6169);
    background-size: 290px 10px !important;
    animation: shimmer 2s ease-in-out infinite !important;
  }
  @keyframes shimmer{
    0%{
      background-position: 0 0;
    }
    50%{
      background-position: -50px 0;
    }
    100%{
      background-position: 0 0;
    }
  }
</style>

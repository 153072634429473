// Force HTTPS
if(process.env.NODE_ENV === 'production' && location.protocol !== 'https:') {
  location.replace(`https:${location.href.substring(location.protocol.length)}`);
}

import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-2';
Vue.use(TawkMessengerVue, {
  propertyId : '6467e3fb74285f0ec46c8338',
  widgetId : '1h0qt54l5'
});
Vue.config.productionTip = false
import VueStripeMenu from 'vue-stripe-menu';
Vue.use(VueStripeMenu);

import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

window.app = new Vue({
  router,
  store,
  render: h => h(App),
  computed: {
    os() {
      const platform = navigator.platform.toLowerCase();
      if (platform.includes('win')) {
        return 'windows';
      } else if (platform.includes('mac')) {
        return 'mac';
      } else if (platform.includes('linux')) {
        return 'linux';
      } else if (platform.includes('iphone') || platform.includes('ipad') || platform.includes('ipod')) {
        return 'ios';
      } else if (/android/.test(platform)) {
        return 'android';
      } else {
        return 'unknown';
      }
    },

    browser(){
      let userAgent = navigator.userAgent;
      if (/Edg\//.test(userAgent)) {
        return 'edge';
      } else if (/Chrome\//.test(userAgent)) {
        return 'chrome';
      } else if (/Firefox\//.test(userAgent)) {
        return 'firefox';
      } else if (/MSIE |Trident\//.test(userAgent)) {
        return 'ie';
      } else if (/Safari\//.test(userAgent)) {
        return 'safari';
      }
      return 'unknown';
    }
  }
}).$mount('#app')

import axios from 'axios';
import Vue from 'vue';

export default {
  name: 'user',
  namespaced: true,
  state: {
    user: { },
    signInSuccessCallback: null,
    signInCancelCallback: null,
    showAuthModal: false,
    afterAuthRoute: null
  },
  mutations:{
    SET(state, user){
      console.log('SETTING', user);
      Object.keys(user).forEach(key => {
        Vue.set(state.user, key, user[key])
      })
    },
    UNSET(state){
      const email = state.user.email;
      Vue.set(state, 'user', {
        email
      })
    },
    SET_SIGN_IN_SUCCESS_CALLBACK(state, callback){
      Vue.set(state, 'signInSuccessCallback', callback)
    },
    SET_SIGN_IN_CANCEL_CALLBACK(state, callback){
      Vue.set(state, 'signInCancelCallback', callback)
    },
    SET_SHOW_AUTH_MODAL(state, value){
      Vue.set(state, 'showAuthModal', value)
    }
  },
  actions: {

    async loadMe(context){
      const response = await axios.get(`/api/users/me`)
      if(response && response.data){
        context.commit('SET', response.data)
        return response.data
      }else{
        return response;
      }
    },
    async authenticate(context){
      return new Promise((resolve, reject) => {
        if(context.getters['isSignedIn']){
          resolve(true)
        }else{
          context.dispatch('loadMe')
            .finally(() => {
              if(context.getters['isSignedIn']){
                resolve(true)
              }else{
                reject();
                // context.commit('SET_SIGN_IN_SUCCESS_CALLBACK', () => { resolve(true) });
                // context.commit('SET_SIGN_IN_CANCEL_CALLBACK', () => { resolve(false) });
              }
            })
        }
      })
    },
    async signup(context, user){
      // HOW TO DO AXIOS ASYNC WITH ERROR MESSAGE HANDLING
      try{
        const response = await axios.post(`/api/users/signup`, user)
        context.commit('SET', response.data)
      }catch(e){
        console.log('could not sign in');
        throw new Error(e.response.data.message);
      }
    },
    async signin(context, user){
      // HOW TO DO AXIOS ASYNC WITH ERROR MESSAGE HANDLING
      try{
        const response = await axios.post(`/api/users/signin`, user)
        context.commit('SET', response.data)
      }catch(e){
        console.log('could not sign in');
        throw new Error(e.response.data.message);
      }
    },
    async requestSignInLink(context, email){
      return await axios.post(`/api/users/email-one-time-sign-in-link`, {email})
    },
    async signInWithTOTP(context, token){
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/users/signin-totp`, {token})
          .then(response => {
            if(response && response.data){
              context.commit('SET', response.data)
              resolve();
            }else{
              return response;
            }
          })
          .catch(({response}) => {
            if(response.data && response.data.message){
              reject(response.data.message)
            }else{
              reject('Could not sign in')
            }
          });
      });

    },
    async signout(context){
      await axios.get(`/api/users/signout`)
      context.commit('UNSET')
    },
    async update (context, data) {
      await axios.put('/api/users/me', data)
      await context.dispatch('loadMe')
    },
    async claimMachine(context, user){
      const response = await axios.post(`/api/users/claim-machine`, user)
      if(response && response.data && response.data.user){
        context.commit('SET', response.data.user)
        return response.data
      }else{
        return response;
      }
    },
    requestKey(context, email){
      return new Promise((resolve, reject) => {
        axios
          .post(`/api/users/request-key`, email)
          .then(response => {
            if(response && response.data){
              if(response.data.error){
                reject(response.data.error)
              }else{
                resolve('Your key has been sent to your email.')
              }
            }else{
              return response;
            }
          }).catch(reject)
      })
    },
    tag(context, tag){
      return new Promise((resolve, reject) => {
        if(context.state.user._id){
          axios
              .post(`/api/users/${context.state.user._id}/tags`, {tag})
              .then(response => {
                if (response && response.data && response.data._id) {
                  context.commit('SET', response.data)
                  resolve(response.data)
                }
              }).catch(e => {
            console.error(e);
            reject(e);
          })
        }else{
          reject('User not signed in')
        }
      });
    },
  },
  getters: {
    me: state => state.user,
    name: state => state.user.name,
    email: state => state.user.email || '',
    label: state => state.user.name || state.user.email,
    hasTag: state => query => !!(state.user.tags || []).find(
      tag => tag.toLowerCase() === query.toLowerCase()
    ),
    requestedBetaInvite: (state, getters) => getters.hasTag('requested desktop'),
    acceptedBetaInvite: (state, getters) => getters.hasTag('Accepted Desktop'),
    isDesktopCustomer: () => true,
    invited: (state, getters) => getters.hasTag('invited'),
    inviter: (state, getters) => getters.hasTag('inviter'),

    isSignedIn: state => !!(state.user._id),
    isAuthenticated: state => !!(state.user._id),
    isAdmin: state => state.user.role === 'admin',
    postPurchaseSurvey: state => state.user ? state.user.postPurchaseSurvey : {}
  }
}

import Vue from 'vue';
import VueRouter from 'vue-router';
import store from './store';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  routes: [
    // {
    //   name: 'blog',
    //   path: '/blog',
    //   component: () => import('@/pages/PageBlog.vue')
    // },
    // {
    //   name: 'blog-category',
    //   path: '/blog/:slug',
    //   component: () => import('@/pages/PageBlog.vue')
    // },
    // {
    //   name: 'blog-article',
    //   path: '/blog/:category/:slug',
    //   component: () => import('@/pages/PageBlogArticle.vue')
    // },
    {
      name: 'storyboard',
      path: '/b/:storyboardId',
      component: () => import('@/pages/PageStoryboard.vue')
    },
    {
      name: 'pricing',
      path: '/pricing',
      component: () => import('@/pages/PageSelectPlan.vue'),
      meta: {
        index: true,
        changefreq: 'monthly',
        priority: 0.8
      }
    },
    {
      name: 'privacy',
        path: '/privacy',
        component: () => import('@/pages/PagePrivacyPolicy.vue'),
        meta: {
          index: true,
          changefreq: 'monthly',
          priority: 0.8
        }
    },
    {
      name: 'pricing-with-coupon',
      path: '/deal',
      component: () => import('@/pages/PageSelectPlan.vue'),
      meta: {
        index: true,
        changefreq: 'monthly',
        priority: 0.8
      }
    },
    {
      name: 'support',
      path: '/support',
      component: () => import('@/pages/PageSupport.vue'),
      meta: {
        index: true,
        changefreq: 'monthly',
        priority: 0.6
      }
    },
    {
      name: 'blog-all',
      path: '/blog/all',
      component: () => import('@/pages/PageAdminArticles.vue'),
      meta: {
        requireAdmin: true
      }
    },
    {
      name: 'blog-create',
      path: '/blog/new',
      component: () => import('@/pages/PageAdminArticleForm.vue'),
      meta: {
        requireAdmin: true
      }
    },
    {
      name: 'blog-edit',
      path: '/blog/:articleId/edit',
      component: () => import('@/pages/PageAdminArticleForm.vue'),
      meta: {
        requireAdmin: true
      }
    },
    {
      name: 'beta',
      path: '/beta',
      component: () => import('@/pages/PageOriginal.vue')
    },
    {
      name: 'pro',
      path: '/pro',
      component: () => import('@/pages/PageOriginal.vue'),
    },
    {
      name: 'realdeal',
      path: '/realdeal',
      component: () => import('@/pages/PageRealDeal.vue'),
    },
    {
      name: 'signup',
      path: '/signup',
      component: () => import('@/pages/PageAuth.vue'),
      meta: {
        index: true,
        changefreq: 'monthly',
        priority: 0.9
      }
    },
    {
      name: 'signin',
      path: '/signin',
      component: () => import('@/pages/PageAuth.vue'),
      meta: {
        index: true,
        changefreq: 'monthly',
        priority: 0.7
      }
    },
    {
      // Temporary, one-time password sign in link
      name: 'totp-signin',
      path: '/totp/:token',
      component: () => import('@/pages/PageAuth.vue'),
    },
    {
      name: 'checkout',
      path: '/checkout',
      component: () => import('@/pages/PageOriginal.vue')
    },
    {
      name: 'onboard',
      path: '/welcome',
      component: () => import('@/pages/PageOnboard.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'download',
      path: '/download',
      component: () => import('@/pages/PageDownload.vue'),
      meta: {
        requireAuth: true,
        index: true,
        changefreq: 'monthly',
        priority: 0.8
      }
    },
    {
      name: 'PageElements',
      path: '/elements',
      component: () => import('@/pages/PageElements.vue'),
      meta: { requireAuth: false }
    },
    {
      name: 'marketing',
      path: '/marketing',
      component: () => import('@/pages/PageCustomerMarketingAgencies.vue'),
      meta: { requireAuth: false }
    },
    {
      name: 'video-production',
      path: '/video-production',
      component: () => import('@/pages/PageCustomerVideoProductionStudios.vue'),
      meta: {
        index: false,
        changefreq: 'monthly',
        priority: 1
      }
    },
    {
      name: 'AccountUserView',
      path: '/account/user',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountSubscriptionMigrate',
      path: '/account/subscription/migrate/:plan/:interval',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountSyncAppLicense',
      path: '/account/sync-app-license',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountDownload',
      path: '/account/download',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountMachinesView',
      path: '/account/machines',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountSubscriptionView',
      path: '/account/subscription',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountSubscriptionManage',
      path: '/account/subscription/manage',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountSubscriptionCancel',
      path: '/account/subscription/cancel',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountSubscriptionKeep',
      path: '/account/subscription/keep',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountSubscriptionCancelConfirm',
      path: '/account/subscription/cancel/confirm',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountSubscriptionCancelComplete',
      path: '/account/subscription/cancel/complete',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountSubscriptionReactivated',
      path: '/account/subscription/cancel/complete',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountInvoicesView',
      path: '/account/receipts',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountAdminDashboard',
      path: '/account/admin',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountAdminChat',
      path: '/account/admin/chat',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'AccountAdminChatAssistantSettings',
      path: '/account/admin/chat/assistant/:assistantId',
      component: () => import('@/pages/PageAccount.vue'),
      meta: { requireAuth: true }
    },
    {
      name: 'view',
      path: '/checkout/key/:key',
      component: () => import('@/pages/PageViewKey.vue'),
    },
    {
      name: 'analytics',
      path: '/analytics',
      component: () => import('@/pages/PageAnalytics.vue'),
    },
    {
      name: 'feature-upvotes',
      path: '/feature-upvotes',
      component: () => import('@/pages/PageOriginal.vue'),
      beforeEnter(){
        window.location.href = `https://storyfolder.featureupvote.com/`
      }
    },
    {
      name: 'original',
      path: '/original',
      component: () => import('@/pages/PageOriginal.vue'),
    },
    {
      name: 'status',
      path: '/status',
      component: () => import('@/pages/PageStatus.vue')
    },
    {
      name: 'Home',
      path: '/',
      component: () => import('@/pages/PageDesktop.vue'),
      meta: {
        index: true,
        changefreq: 'weekly',
        priority: 1
      }
    },
    {
      name: 'shotdeck',
      path: '/shotdeck',
      component: () => import('@/pages/PageDesktop.vue'),
      meta: {
        source: 'shotdeck'
      }
    },
    {
      name: 'shot-deck',
      path: '/shot-deck',
      component: () => import('@/pages/PageDesktop.vue'),
      meta: {
        source: 'shot-deck'
      }
    },
    {
      name: 'desktop-invite',
      path: '/desktop-invite',
      component: () => import('@/pages/PageDesktopInviteRequest.vue')
    },
    {
      name: 'desktop-invite-accept',
      path: '/desktop-invite-accept',
      component: () => import('@/pages/PageDesktopInviteAccept.vue')
    },
    {
      name: 'preorder',
      path: '/preorder',
      component: () => import('@/pages/PagePreorder.vue'),
      meta: {
        bodyClass: 'light-theme'
      }
    },
    {
      name: 'auth-app',
      path: '/auth-app',
      component: () => import('@/pages/PageAuthApp.vue'),
      meta: { requireAuth: true }
    },
    {
      path: '*',
      redirect: '/'
    }
  ]
});

router.beforeEach(async (to, from, next) => {
  if(to.name === 'beta'){
    localStorage.setItem('basic', 'true');
  }else if(to.name === 'pro'){
    localStorage.setItem('basic', 'false')
  }

  if("requireAdmin" in to.meta){
    console.log('requireAdmin');
    if(store.state.user.user.email == 'jeff@jeffjassky.com'){
      next()
    }else {
      next({name: 'signin', query: {redirect: to.fullPath}})
    }
  }

  if("requireAuth" in to.meta){
    console.log('requireAuth');
    try{
      await store.dispatch('user/authenticate')
      next();
    }catch(e){
      next({name: 'signin', query: { redirect: to.fullPath }})
    }
  }
  const bodyTag = document.getElementsByTagName('body')[0];
  if(to.meta && to.meta.bodyClass){
    // Add body class
    bodyTag.classList.add(to.meta.bodyClass)
  }else if(from.meta && from.meta.bodyClass){
    // Remove body class
    bodyTag.classList.remove(from.meta.bodyClass)
  }
  next();
})

export default router;

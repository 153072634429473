<template>
  <div id="app-container">
    <router-view :key="$route.path"/>
    <script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          "name": "StoryFolder",
          "operatingSystem": "Windows",
          "applicationCategory": "MultimediaApplication",
          "offers": {
            "@type": "Offer",
            "price": "9.95",
            "priceCurrency": "USD"
          }
        }
    </script>
    <script type="application/ld+json">
        {
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          "name": "StoryFolder",
          "operatingSystem": "macOS",
          "applicationCategory": "MultimediaApplication",
          "offers": {
            "@type": "Offer",
            "price": "9.95",
            "priceCurrency": "USD"
          }
        }
    </script>
  </div>
</template>

<script lang="ts">



export default {
  name: 'App',
  components: {
  },
  computed: {
    user(){
      return this.$store.getters['user/me']
    }
  },
  watch: {
    '$route' () {
      // Deep link to app when $route.query.open is set
      if(this.$route.query.open){
        window.location.href="storyfolder://"+this.$route.query.open
      }
      // Set the user email address if it's in the query string
      if(this.$route.query.email){
        if(!this.$store.getters['user/isAuthenticated']) {
          this.$store.commit('user/SET', {
            email: this.$route.query.email
          })
        }
      }
      if(this.$route.hash){
        const hash = this.$route.hash.replace('#', '');
        this.$nextTick(() => {
          const anchor = document.querySelector(`a[name="${hash}"]`);
          if(anchor) {
            console.log(anchor);
            console.log('scrolling');
            anchor.scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'nearest'
            });
          }
        });
      }
    },
    user(user){
      if(user){
        try{
          this.$tawkMessenger.visitor({
            name : user.fullName,
            email : user.email
          });
        } catch(e){
          console.log('Could not set Tawk.to visitor name and email');
        }
        // set ga4 user id
        window.gtag('set', 'user_id', user._id);
        window.gtag('set', 'user_properties', {
          email: user.email,
          name: user.fullName,
          subscribed: user.subscribed
        });
      }
    }
  },
  created(){
    this.$store.dispatch('user/loadMe');
    this.$store.dispatch('coupon/load');

    this.$watch(
        () => this.$store.state.user.user,
    user => {
          console.log('email changed');
          if(user.email){
            this.$store.dispatch('coupon/load');
          }
        }
    );
    this.$watch(
        () => this.$store.getters['user/isSignedIn'],
        isSignedIn => {
            if(isSignedIn){
                console.log('User signed in')
                if(typeof this.$store.state.user.signInSuccessCallback === 'function'){
                    this.$store.state.user.signInSuccessCallback()
                }

                this.$store.dispatch('user/tag', this.$root.os);
                this.$store.dispatch('accounts/load');
                this.$store.dispatch('subscriptions/load');
                window.gtag('event', 'login', {
                  'user_id': this.$store.getters['user/me']._id
                });
            }else{
                console.log('User signed out')
                // Execute all ON_DEAUTHENTICATE mutations
                Object.keys(this.$store._mutations)
                    .filter(mutation => mutation.includes('ON_DEAUTHENTICATE'))
                    .forEach(mutation => this.$store.commit(mutation))

                // Check the route, make sure auth isn't necessary.
                if("requireAuth" in this.$route.meta){
                    this.$router.push({name: 'Home'});
                }
            }
        }
    );
  }
}
</script>

<style lang="less">
  @import 'less/variables';
  @import 'less/tooltips';
  html, body {
    margin: 0;
    padding: 0;
    line-height: 1.6;
    font-size: 14px;
    background-color: #131728;
    background-image: linear-gradient(45deg, #131728, #301b3a);
    font-family: Montserrat, sans-serif;
  }
  ::selection {
    background: fade(#4a3fc8, 50%); /* WebKit/Blink Browsers */
  }
  #app-container {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: flex;
    justify-content: space-between;
    min-height: 100vh;
    color: white;
    flex-direction: column;
  }

  .app-width{
    width: 100%;
    margin-left:auto;
    margin-right:auto;
    box-sizing: border-box;
    padding-left: 1em;
    padding-right: 1em;
  }
  @media(min-width: @firstbreakpoint){
    .app-width{
      width: 600px;
    }
  }
  @media(min-width: @secondbreakpoint){
    .app-width{
      width: 800px;
    }
  }
  @media(min-width: @thirdbreakpoint){
    .app-width{
      width: 1000px;
    }
  }
  @media(min-width: @fourthbreakpoint){
    .app-width{
      width: 1300px;
    }
  }
  @media(min-width: @fifthbreakpoint){
    .app-width{
      width: 1500px;
    }
  }

  a {
    cursor: pointer;
  }
  .color-1{
    color: @color1;
  }
  .color-2{
    color: @color2;
  }
  .color-3{
    color: @color3;
  }
  .color-4{
    color: @color4;
  }
  .color-5{
    color: @color5;
  }
  .color-6{
    color: @color6;
  }

  .green{
      color: @green
  }
  .bg-color-1{
    color: @color1;
  }
  .bg-color-2{
    color: @color2;
  }
  .bg-color-3{
    color: @color3;
  }
  .bg-color-4{
    color: @color4;
  }
  .bg-color-5{
    color: @color5;
  }
  .bg-color-6{
    color: @color6;
  }

  .bold{
    font-weight: bolder;
  }
  .underline {
    text-decoration: underline;
  }
  .white{
      color: white;
  }

  p, ul, h1.p{
    overflow-wrap: anywhere;
    color: #999999;
    font-size: 1em;
    font-weight: 400;
  }
  h1{
    font-family: Montserrat, sans-serif;
    font-size: 27px;
    margin: 0;
    line-height: 1.1em;
    letter-spacing: -0.035em;
    font-weight: 700;
  }
  h2, p.h2{
    font-family: Montserrat, sans-serif;
    font-weight: normal;
    font-size: 2em;
    margin: 1.25em 0 1em 0;
    line-height: 1.2em;
  }
  h3, p.h3{
    font-family: Montserrat, sans-serif;
    font-weight: 400;
    font-size: 1.5em;
    margin: 0 0 1.25em 0;
    line-height: 1.4em;
    color: #999;
  }
  h1.center, h2.center, h3.center{
    display: block;
    margin:0 auto;
    background: -webkit-linear-gradient(45deg, #a7e5e0, #e0b2f5, #fc6169);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  a{
    color: white;
    text-decoration: none;
  }
  ol, ul{
    text-align: left;
    padding-left: 1.5em;
  }
  li{
    margin-bottom: 1em;
    padding-left: 1em;
  }

  @media(min-width: @zeroethbreakpoint){
    h1 {
      font-size: 2.5rem;
    }
  }
  @media(min-width: @firstbreakpoint){
    h1 {
      font-size: 40px;
    }
    h3, p.h3{
      font-size: 30px;
    }
    body{
        font-size: 16px;
    }
  }
  @media(min-width: @secondbreakpoint){
    h1 {
      font-size: 50px;
    }
  }
  @media(min-width: @thirdbreakpoint){
    h1 {
      font-size: 55px;
    }
  }
</style>
